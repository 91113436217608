'use strict';

angular.module('windmanagerApp')
  .filter('idInArray', function () {
    return function (inputs, array, inverse) {
      var in_array, obj, res, _i, _len;
      if (inverse == null) {
        inverse = false;
      }
      res = [];
      if (!((inputs != null) && (array != null))) {
        return res;
      }
      for (_i = 0, _len = inputs.length; _i < _len; _i++) {
        obj = inputs[_i];
        in_array = array.indexOf(obj._id) !== -1;
        if (inverse) {
          in_array = !in_array;
        }
        if (in_array) {
          res.push(obj);
        }
      }
      return res;
    };
  });